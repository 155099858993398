import { Component } from '@angular/core';
// import { io, Socket } from 'socket.io-client';

import {Observable} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'biersMonit';
  // private socket: Socket;

  constructor() {

    // this.socket = io.connect('http://221.166.203.165:3333');
    // console.log(this.socket);
    // // this.socket.on('connection', function(socket){
    // //   console.log(this.socket);
    //
    // });

    // this.socket.on('connect', msg => {
    //   console.log("conected");
    // });
    // this.socket.connect();
    // // this.socket.emit('sendMessage', { message: msg });

  }
}
